import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GetName, GetDescription } from "../../helpers/query-data";
import { GQLComponent } from "../../types";

const UseStyles = makeStyles({
  name: {
    color: "#00958D",
  },
});

type DescriptionProps = { name: GQLComponent; description: GQLComponent };

const Description = (props: DescriptionProps): JSX.Element => {
  const Clases = UseStyles();
  const Name = GetName(props.name);
  const Description = GetDescription(props.description);

  return (
    <div>
      <Typography variant="h5" className={Clases.name}>
        {Name}
      </Typography>
      {Description.map((node, index) => (
        <Typography variant="body2" key={index}>
          {node}
        </Typography>
      ))}
    </div>
  );
};

export default Description;
