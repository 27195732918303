import React from "react";
import Description from "./description";
import InfoTable from "./info-table";
import Image from "../image";
import { InfoTabs as Constants } from "../../helpers/constants";
import {
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { GQLComponent } from "../../types.js";

const UseStyles = makeStyles({
  appBar: {
    backgroundColor: "#00958D",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },

  tabs: {
    color: "white",
  },

  divider: {
    marginBottom: "2rem",
  },

  imageTab: {
    width: "fit-content",
    height: "fit-content",
  },
});

const TabUseStyles = makeStyles({
  card: {
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
  },
});

type TabPanelProps = {
  children: unknown;
  value: number;
  index: number;
} & Record<string, unknown>;

const TabPanel = (props: TabPanelProps) => {
  const TabClasses = TabUseStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Card elevation={5} className={TabClasses.card}>
          <CardContent>{children}</CardContent>
        </Card>
      )}
    </div>
  );
};

type InfoTabsProps = {
  data: (GQLComponent & { url: string; altText: string })[];
};

/*
 * data ->
 *  [0] -> Name component
 *  [1] -> Description component
 *  [2] -> Information component
 *  [3] -> Decoration location image
 */
const InfoTabs = (props: InfoTabsProps): JSX.Element => {
  const Classes = UseStyles();
  const [Value, SetValue] = React.useState(0);

  /* Event handler for clicking on different tabs */
  const handleChange = (_event, newValue) => {
    SetValue(newValue);
  };

  return (
    <div>
      <AppBar position="relative" className={Classes.appBar}>
        <Tabs
          TabIndicatorProps={{ style: { background: "black" } }}
          value={Value}
          onChange={handleChange}
          className={Classes.tabs}
        >
          <Tab label={Constants.ProductDescText} />
          <Tab label={Constants.AdditionalInfoText} />
          <Tab label={Constants.DecorationLocationText} />
        </Tabs>
      </AppBar>
      <TabPanel value={Value} index={0}>
        <Typography variant="h5">{Constants.DetailsText}</Typography>
        <Divider className={Classes.divider} />
        <Description name={props.data[0]} description={props.data[1]} />
      </TabPanel>
      <TabPanel value={Value} index={1}>
        <Typography variant="h5">{Constants.AdditionalInfoText}</Typography>
        <Divider className={Classes.divider} />
        <InfoTable infoComponent={props.data[2]} />
      </TabPanel>
      <TabPanel value={Value} index={2}>
        <Typography variant="h5">{Constants.DecorationLocationText}</Typography>
        <Divider className={Classes.divider} />
        {!props.data[3].url && (
          <Typography variant="body2">
            {Constants.NoDecorLocImageText}
          </Typography>
        )}
        <div className={Classes.imageTab}>
          <Image url={props.data[3].url} altText={props.data[3].altText} />
        </div>
      </TabPanel>
    </div>
  );
};

export default InfoTabs;
