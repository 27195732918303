import React from "react";
import ProductImage from "./product-image";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  dialog: {
    "& div div": {
      margin: "0",
    },
  },

  closeButton: {
    position: "absolute",
    right: ".75rem",
    top: ".75rem",
    color: "lightgrey",

    "&.MuiButton-root": {
      minWidth: "0",
      padding: "0",
    },
  },
});

type ImageDialogProps = {
  showImageDialog: boolean;
  setShowDialog: (showDialog: boolean) => void;
  optionsState: {
    image: string;
    defaultImage: string;
    hasValidSelection: boolean;
  };
};

/*
 * optionsState ->
 *   image -> Selected image
 *   defaultImage -> Default variant image
 *   hasValidSelection -> If true, use image. If false, use defaultImage
 * showImageDialog -> If true, an expanded product image dialog is shown
 * setShowDialog -> Shows or hides the image dialog
 */
const ImageDialog = (props: ImageDialogProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xl"
        className={Classes.dialog}
        open={props.showImageDialog}
        onClose={() => props.setShowDialog(false)}
      >
        <ProductImage
          optionsState={props.optionsState}
          showExpandButton={false}
        />
        <Button
          variant="outlined"
          className={Classes.closeButton}
          onClick={() => props.setShowDialog(false)}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Dialog>
    </React.Fragment>
  );
};

export default ImageDialog;
