import React from "react";
import { GQLComponent } from "../../types";
import { GetAllPriceSectionsContent } from "../../helpers/query-data";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Table,
  TableHead,
  makeStyles,
} from "@material-ui/core";

const UseStyles = makeStyles({
  table: {
    marginBottom: "0",
  },

  tableCellHead: {
    background: "#00958D",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontWeight: "bold",
    color: "white",
    padding: "10px",
    "&:first-child": {
      borderLeft: "none",
    },
  },

  tableCellBody: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    padding: "10px",
  },
});
type PriceTableProps = { priceComponent: GQLComponent };

/*
 * priceComponent -> Crystallize properties table component
 */
const PriceTable = (props: PriceTableProps): JSX.Element => {
  const Classes = UseStyles();
  const PriceContentSections = GetAllPriceSectionsContent(props.priceComponent);

  return (
    <TableContainer component={Paper} elevation={5}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={Classes.tableCellHead}>Quantity</TableCell>
            {PriceContentSections[0]?.properties.map((node, index) => (
              <TableCell key={index} className={Classes.tableCellHead}>
                {node.key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {PriceContentSections.map((section, index) => (
            <TableRow key={index}>
              <TableCell className={Classes.tableCellBody}>
                Price ({section.title})
              </TableCell>
              {section.properties.map((node, index) => (
                <TableCell key={index} className={Classes.tableCellBody}>
                  {node.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceTable;
