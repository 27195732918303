import React from "react";
import Image from "../image";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import { Button, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  imageWrapper: {
    position: "relative",
    border: "1px solid lightgrey",
    marginTop: ".5rem",
  },

  usaMade: {
    position: "absolute",
    width: "5rem",
    height: "auto",
    top: ".75rem",
    left: ".75rem",
  },

  expandButton: {
    position: "absolute",
    color: "lightgrey",
    borderRadius: "0",
    bottom: ".75rem",
    left: ".75rem",
  },

  hideButton: {
    display: "none",
  },
});

type ProductImageProps = {
  optionsState: {
    image: { url: string; altText: string };
    defaultImage: { url: string; altText: string };
    hasValidSelection: boolean;
  };
  setShowDialog: (show: boolean) => void;
  showExpandButton: boolean;
  usaMade: boolean;
};

/*
 * optionsState ->
 *   image -> Selected image
 *   defaultImage -> Default variant image
 *   hasValidSelection -> If true, use image. If false, use defaultImage
 * setShowDialog -> If true, an expanded product image dialog is shown
 * showExpandButton -> If true, a button appears that opens the expanxed product image
 * usaMade -> If true, the product is made in the USA
 */
const ProductImage = (props: ProductImageProps): JSX.Element => {
  const Classes = UseStyles();

  let url;
  let altText = null;
  const HasCustomImage =
    props.optionsState.hasValidSelection && props.optionsState.image !== null;
  if (HasCustomImage) {
    url = props.optionsState.image.url;
    altText = props.optionsState.image.altText;
  } else {
    url = props.optionsState.defaultImage.url;
    altText = props.optionsState.defaultImage.altText;
  }

  return (
    <React.Fragment>
      <div className={Classes.imageWrapper}>
        {props.usaMade && (
          <div className={Classes.usaMade}>
            <Image url="/american-flag.png" altText="USA Made" />
          </div>
        )}
        <Image url={url} altText={altText} />
        <Button
          variant="outlined"
          className={
            props.showExpandButton ? Classes.expandButton : Classes.hideButton
          }
          onClick={() => props.setShowDialog(true)}
        >
          <SettingsOverscanIcon fontSize="large" />
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ProductImage;
